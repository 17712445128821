import React from 'react'
import { Link } from 'gatsby'
import { styles } from '../utils/theme'

const NotFoundPage = () => (
  <div className="flex-auto flex flex-column justify-center items-center bg-sand pt4">
    <h1 className="mb3 f-headline coffee">NOT FOUND</h1>
    <p className="mb3 f4">Oh no! There's no page here... </p>{' '}
    <p className="mb3">Maybe you wanted this one?</p>
    <Link to="/" className={styles.buttonGold + 'mb4'}>
      Home
    </Link>
  </div>
)

export default NotFoundPage
